import React, { useContext, useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, Result } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './index.css';
import { useHttp } from '../../hooks/http.hook';
import { useMessage } from '../../hooks/message.hook';
import { AuthContext } from '../../context/AuthContext';
import Layout from 'antd/lib/layout/layout';
import { useNavigate } from 'react-router-dom';
import { PASSWORD_PATTERN } from '../../utils/consts';

const LoginForm = () => {
  const auth = useContext(AuthContext);
  const history = useNavigate();
  const message = useMessage();
  const { loading, error, request, clearError } = useHttp();
  const [forbidden, setForbidden] = useState(false);
  const reloadPage = () => window.location.reload();
  const [form, setForm] = useState({
    email: null,
    password: null,
  });

  useEffect(() => {
    error && message(error);
    clearError();
  }, [error, message, clearError]);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', { ...form });
      if (data.user) {
        auth.login(
          data.tokens.access.token,
          data.user.id,
        );
        history('/');
      } else {
        setForbidden(true);
      }
    } catch (e) {
      console.log('error-loginHandler >>> ', e);
    }
  };

  return (
    <Layout className="login-form-container">
      {forbidden ? <Result
        status="403"
        title="403"
        subTitle="К сожалению, вам отказано в доступе"
        extra={<>
        <Button type="primary" onClick={reloadPage}>Выход</Button>
        <Button href='https://t.me/svohelp2023' type='default'
        style={{position: 'absolute', right: '40px', top: '5%'}}>Связаться с поддержкой</Button></>}
      />
        :
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Неверный адрес почты',
              },
              {
                required: true,
                message: 'Пожалуйста, введите почту',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="email"
              name="email"
              value={form.email}
              onChange={changeHandler}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                min: 6,
                pattern: PASSWORD_PATTERN,
                message: 'Не менее 6 символов, минимум одна буква и одна цифра',
              },
              {
                required: true,
                message: 'Пожалуйста, введите пароль',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Пароль"
              name="password"
              value={form.password}
              onChange={changeHandler}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Запомнить</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={form.email && form.password && loginHandler}
              disabled={loading}
              loading={loading}
            >
              Войти
            </Button>
          </Form.Item>
        </Form>}

    </Layout>
  );
};

export default LoginForm;
