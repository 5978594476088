import React, { useState } from 'react';
import { Button, Modal, Select, Form, notification } from 'antd';
import { useHttp } from '../../hooks/http.hook';
import { emit } from '../../socket';
import { useParams } from 'react-router-dom';

const InviteToCallModal = ({ userInfo, callParticipants }) => {
  const { id: roomName, fullRoomName } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { request } = useHttp();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const openNotification = (message, description) => {
    api.info({
      message: message,
      description: description,
      placement: 'topRight',
    });
  };

  const fetchListUsersTable = async () => {
    try {
      const resData = await request(`/api/users/getList`);
      return setData(
        resData
          .map((el, i) => ({ ...el, key: i + 1 }))
          .filter((el) => el.id !== userInfo.userId && !callParticipants.find((user) => el.name === user))
      );
    } catch (error) {
      console.log('error-fetchListUsersTable >>>', error);
    }
  };

  const showModal = async () => {
    await fetchListUsersTable();
    setOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
        setOpen(false);
      })
      .catch((info) => {
        console.log('handleOk: Validate failed -> ', info.errorFields);
      });
  };

  const onFinish = async (values) => {
    try {
      emit('inviteToCall', {
        users: values.users,
        roomName,
        fullRoomName,
        inviter: userInfo.name ? userInfo.name : 'admin',
      });
      openNotification('Приглашение отправлено', '');
    } catch (error) {
      console.log(`failed: ${error}`);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Button type="primary" onClick={showModal} style={{ margin: '0 auto', width: '100%' }}>
        Пригласить участников
      </Button>
      <Modal title="Отправить приглашение" open={open} onOk={handleOk} onCancel={handleCancel} cancelText="Отмена">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item label="Участники " name="users">
            <Select
              showSearch
              mode="multiple"
              placeholder="Список участников"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={data?.map(({ id, name, email }) => ({ value: id, label: name || email }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InviteToCallModal;
