import React, {useEffect, useState} from 'react';
import { useHttp } from '../../hooks/http.hook';
import {Button, Form, Modal, Space, Table, Radio} from 'antd';
import {CloudDownloadOutlined, DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import { message, Upload } from 'antd';
import './index.css';
const { Dragger } = Upload;

export const GeoData = () => {
  const { request } = useHttp();
  const [actual, setActual] = useState('strongPoint');
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [isGeoModalOpen, setIsGeoModalOpen] = useState(false);
  const radioOptions = [
    {
      label: 'Опорные пункты',
      value: 'strongPoint'
    },
    {
      label: 'ЛБС',
      value: 'LBS'
    }
  ]

  useEffect(() => {
    fetchGeoData(actual);
  }, [actual])

  const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};

  const props = {
    name: 'file',
    multiple: true,
    action: `/api/lbs/kml_map/upload/${actual}`,
    headers: {
      authorization: `Bearer ${userData.token}`
    },
    form: form,
    onChange(info) {
      const {status} = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} успешно загружен на сервер.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} ошибка загрузки.`);
      }
    }
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'filename',
      key: 'filename',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Последнее изменение',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => <b>{new Date(record.updatedAt).toLocaleString()}</b>
    },
    {
      title: 'Действия',
      key: 'action',
      render: (_, record) => (
          <Space size="small">
            <Button type="default" icon={<CloudDownloadOutlined />} onClick={() => handleDownload(record.id)}>Скачать</Button>
            <Button type="default" icon={<DeleteOutlined />} onClick={async () => {
              deleteHandler(record.filename)
            }}>Удалить</Button>
          </Space>
      ),
    },
  ];
  const deleteHandler = async (filename) => {
    await request(`/api/lbs/kml_map/delete`, 'POST', {
      filename: filename,
    });
    fetchGeoData(actual)
  }

  const handleDownload = async (id) => {
    try {
      await window.open(`api/lbs/kml_map/download/${id}`);
    } catch (error) {
      console.log(`error inside handleDownload: ${error}`);
    }
  }

  const fetchGeoData = async (actual) => {
    const list = await request(`/api/lbs/kml_map?type=${actual}`);
    setData(list.map((el, i) => ({...el, key: i + 1})));
  }

  return (
      <>
      <div className="GeoData__header">
        <Radio.Group
            options={radioOptions}
            onChange={({target: { value }}) => setActual(value)}
            value={actual}
            optionType="button"
            style={{marginBlock: 10, paddingBlock: 5, float: "right"}}/>
        <Button style={{marginBlock: 10}} type="primary" onClick={() => setIsGeoModalOpen(true)}>Добавить</Button>
        <Modal title="Добавление опорных пунктов"
               open={isGeoModalOpen}
               onOk={() => {
                 setIsGeoModalOpen(false);
                 form.resetFields();
                 fetchGeoData(actual);
               }}
               destroyOnClose={true}
               onCancel={() => setIsGeoModalOpen(false)}
        >
          <Form form={form} layout="vertical">
            <Form.Item valuePropName="fileList">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Перетащите сюда файлы которые хотите загрузить</p>
                <p className="ant-upload-hint">
                  Поддерживается одиночная или массовая загрузка файлов.
                </p>
              </Dragger>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Table columns={columns} dataSource={data} pagination={false} bordered/>
      </>
  )
}
